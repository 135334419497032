@media (max-width: 400px) {
  #features,
  #header,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }
}
